<template>
    <v-container fluid grid-list-md>
        <v-layout justify-center wrap>
            <v-flex
                v-for="(card, index) in cards"
                :key="index"
                xs6 sm6 md6 lg4
            >
            <v-badge overlap class="badge-custom" color="green">
                <span slot="badge" class="badge-font-size" v-if="card.to === 'docsOutBox' && packageStatus > 0">{{ packageStatus }}</span>
                <v-card 
                    flat 
                    justify-center 
                    :color="card.color" 
                    @click="selectFunc(index)" 
                    class="card-title" 
                    :style="{ 'box-shadow': card.shadowColor }" 
                >
                    <v-card-title class="headline justify-center">
                        <div class="text-xs-center">
                            <v-icon size="55px" dark>{{card.icon}}</v-icon>
                            <div>{{ $t(card.title) }}</div>
                        </div>
                    </v-card-title>
                </v-card>
            </v-badge>
            </v-flex>
        </v-layout>
        <v-layout mt-3>
            <v-flex xs12>
                <v-carousel 
                    height="100%"
                    hide-delimiters 
                    light
                    :cycle="cycle"
                >
                    <v-carousel-item
                        v-for="(instructionPic, index) in instructionPics"
                        :src="instructionPic.src"
                        :key="index"
                        style="height: auto"
                    >
                        <div class="carousel-title">
                            {{ $t(instructionPic.title) }}
                        </div>   
                    </v-carousel-item>
                </v-carousel>
            </v-flex>
        </v-layout>
        <!-- <v-layout mt-3>
            <v-flex>
                <Restaurants></Restaurants>
            </v-flex>
        </v-layout> -->
    </v-container>
</template>

<script>
import axios from 'axios'
import store, { HostUrl, DoorCode, UrlRedirectCode } from '@/store'
import UserSyncedWatcher from "@/mixins/UserSyncedWatcher"
import { parseBoxQR, getAxiosConfig } from "@/utils/AuthService"
import { HOMETYPES } from '@/utils/utils'
import { SideBtnType } from '@/store'
import i18n from '@/i18n'
// import Restaurants from '../components/Restaurants'

export default {
    name: 'traveldocshome',
    mixins: [ UserSyncedWatcher ],
    props: {
        query: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            cycle: false,
            cards: [
                { title: 'home.docs-put-in', color: '#757575', shadowColor: '0 5px 0 0 #424242', icon: 'description', ratio: 0.7, to: 'docsInBox' },
                { title: 'home.docs-take-out', color: '#E53935', shadowColor: '0 5px 0 0 #424242', icon: 'task', ratio: 0.7, to: 'docsOutBox' }
            ],
            originQuery: '',
            parsedQuery: null,
            instructionPics: [
                { title: 'home.docs-put-in-process', src: require('../assets/client-docs_putin_' + i18n.locale + '.svg') },
                { title: 'home.docs-take-out-process', src: require('../assets/client-docs_takeout_' + i18n.locale + '.svg') }
            ],
            packageStatus: 0
        }
    },
    methods: {
        parseQuery() {
            if (this.query && this.query !== this.originQuery) {
                this.parsedQuery = parseBoxQR(this.query, false)
                this.originQuery = this.query
                if (store.getters.user.synced) this.shortcut()
                else this.dialog = true
            }
        },
        shortcut() {
            if (store.getters.user.isStaff) {
                this.$router.push({name: 'staffPutin', params: {box: this.parsedQuery.box_id, door: this.parsedQuery.door_id}})
            } else {
                this.$router.push({name: 'docsInBox', params: {box: this.parsedQuery.box_id, door: this.parsedQuery.door_id}})
            }
        },
        selectFunc: function(index) {
            store.commit('setClientId')
            let card = this.cards[index]
            this.$router.push({ name: card.to })
        },
        getPackageStatus() {
            if (store.getters.user.id === 0) {
                return
            }
            let self = this
            axios.get(HostUrl + '/api/box/11/takeout/friend', getAxiosConfig())
                .then(function(response) {
                    if (response.data.code === 0) {
                        self.packageStatus = response.data.count
                    } else {
                        self.packageStatus = 0
                    }
                })
                .catch(function(error) {
                    console.log('error', error)
                    self.packageStatus = 0
                })
        },
        pageHome() {
            if(store.getters.pageHome !== HOMETYPES.TRAVELDOCS)
                store.commit('setPageHome', HOMETYPES.TRAVELDOCS)
            if (store.getters.user.id === 0 && (location.href.indexOf(DoorCode) !== -1 || location.href.indexOf(UrlRedirectCode) !== -1 )) {
                this.$router.push(`/${i18n.locale}/traveldocshome`)
            }
            if (store.getters.user.id > 0) {
                let unloginflag = localStorage.getItem('unloginflag')
                if (unloginflag && unloginflag === 'reload-traveldocs-takeout') {
                    localStorage.removeItem('unloginflag')
                    this.$router.push(`/${i18n.locale}/docshost/takeout`)
                    console.log('route traveldocs takeout')
                }
            }
        },
        checkCodeLogin() {
            this.pageHome()
        }
    },
    activated() {
        // this.cycle = true
        this.pageHome()
        store.commit('setTitle', 'b-box')
        store.commit('setSideBtnType', SideBtnType.Navi)
        this.parseQuery()
        this.getPackageStatus()

        //重新載入頁面一次
        // if (localStorage.getItem('reloaded')) {
        //     localStorage.removeItem('reloaded')
        // } else {
        //     localStorage.setItem('reloaded', '1')
        //     location.reload()
        // }
    },
    deactivated() {
        this.pageHome()
        console.log('deactivated')
        this.packageStatus = 0
        // this.cycle = false
    },
    // created() {
    //     console.log('created')
    // },
    // beforeMount() {
    //     console.log('beforeMount')
    // },
    mounted() {
        this.checkCodeLogin();
    }
}
</script>

<style scoped>
    .carousel-title {
        position: absolute;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        font-size:  18px;
        font-weight: bold;
        color: white;
        padding: 5px;
        width: 100%;
        text-align: center;
    }

    .card-title {
        color: white;
        font-weight: bold;
        border-radius: 8px;
    }

    .badge-custom {
        display: block;
    }
</style>